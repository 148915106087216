<template>
  <div v-loading="loading" class="page-info">
    <page-information v-if="page" :page="page" @reload="loadData" />
    <page-positions v-if="page" :page="page" />
    <page-prices v-if="page" :page="page" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PageInformation from './components/Information.vue'
import PagePositions from './components/Positions.vue'
import PagePrices from './components/Prices.vue'

import { getBanners } from '@/services/banner'

export default {
  name: 'PagesInfo',
  components: {
    PageInformation,
    PagePositions,
    PagePrices
  },
  data() {
    return {
      loading: false,
      page: null
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      getBanners({ page: 1, per_page: 10000 }).then((response) => {
        this.page = response.result.find((p) => p.id === this.$route.params.id)
        this.loading = false
      }).catch(() => {
        this.page = {}
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-info {
  min-height: 200px;
  .title {
    background-color: #2b314a;
    padding: 15px;
    margin-bottom: 20px;
    h3 {
      margin: 0;
      color: white;
    }
  }
}
</style>

